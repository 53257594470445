<template>
  <modal name="contact-modal">
    <div slot="header">
      <p class="fs-medium m0">
        {{ $t('Zamów telefonicznie') }}
      </p>
    </div>
    <div class="row middle-xs" slot="content">
      <div class="center-xs col-xs-12 col-sm-6">
        <i class="icon-phone cl-alternative" style="font-size: 44px;" />
        <p class="cl-alternative fs-medium">
          {{ $t('Zadzwoń do nas!') }}
        </p>
      </div>
      <div class="col-xs-12 col-sm-6">
        <a href="tel:22-397-43-92" class="cl-alternative h3 weight-700 my15">
          22 397 43 92
        </a>
        <p class="h5 cl-matterhorn">
          {{ $t('Infolinia czynna') }}<br>
          {{ $t('pon - pt:') }} 10:00-18:00<br>
          {{ $t('sb:') }} 10:00-15:00
        </p>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from 'theme/components/core/Modal';

export default {
  name: 'ContactModal',
  components: {
    Modal
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
